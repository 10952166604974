.task-card-container {
  
  border:none !important;
  border-radius: 10px !important;

  .task-card-body-color-0 {
    background-color:rgb(236, 242, 255);
  }
  .task-card-body-color-1 {
    background-color:rgb(254,245,229);
  }
  .task-card-body-color-2 {
    background-color:rgb(232,247,255);
  }
  .task-card-body-color-3 {
    background-color:rgb(253,237,232);
  }

  .card-body {
    border-radius: 10px !important;
  }

  .task-card-title-name {
    font-family: Arial, FF_Din_Font;
    font-weight: bold;
    color: rgb(93,135,255);
  }

  .task-card-number {
    color: rgb(93,135,255);
  }

  .task-card-icon-online {
    // display: block;
    background-color: orange;
    border-radius: 50%;
    // outline: 5px solid rgba(34, 139, 34, 0.266);
    outline: 5px solid rgba(218, 165, 32, 0.477);
    width: 12px;
    height: 12px;
    animation: scale-big-small 1.5s infinite;
  }

  @keyframes scale-big-small {
    0%, 100% {transform: scale(0.5);outline: 5px solid rgba(218, 165, 32, 0);}
    50% {transform: scale(1);outline: 5px solid rgba(218, 165, 32, 0.477);}
  }
}

// Task Sites Map
.task-container-row-2 {

  width: calc(100% + 1.5rem); // full width align dengan total width 4 card di atas (full Row 2)
  
  // *** Container List Group Sites
  .task-list-group-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  // map site
  .task-sites-list {
      background-color: floralwhite;
      height:fit-content;
    }
    
    // open task
  .task-list {
      background-color: #90ee901b;
      height:fit-content;
  }

  // ** Banner Title 'Sites'
  .task-card-sites-title {
      font-family: FF_Din_Font_Medium;
      font-weight: 600;
  }

  // ** Kolom Title
  .task-site-title-column {
      font-family: FF_Din_Font_Medium, Arial;
      font-weight: bold;
      font-size: x-small;
      opacity: 0.5;
  }

  // ** Data
  .task-site-data-column {

    // jarak top and bottom pada data column site
    padding-block: 1px;

    // *** Open Tasks (Icon)
    .task-site-data-column-sub-icon {

        .task-list-data-column-subdata-icon {
            width: 4rem;

            .task-list-data-subdata-icon-container {
                padding:3px;
                // display: block;
                width: 30px;
                height: 30px;
                border-radius: 5px;
            }

            // Open Tasks Icon Color
            .task-list-data-subdata-icon-container-color-0 {
                background-color: rgb(236,242,255);

                .task-list-data-subdata-icon-text-color-0 {
                  color: rgb(93,135,255);
                }
            }

            .task-list-data-subdata-icon-container-color-1 {
                background-color: rgb(230,255,250);

                .task-list-data-subdata-icon-text-color-1 {
                  color: rgb(19,222,185);
                }
            }

            .task-list-data-subdata-icon-container-color-2 {
                background-color: rgb(253,237,232);

                .task-list-data-subdata-icon-text-color-2 {
                  color: rgb(250,137,107);
                }
            }
        }
    }

    .task-site-data-column-sub-1 {

        // *** Open Tasks (data)
        .task-list-data-column-subdata-1 {
            font-family: FF_Din_Font_Medium, Arial;
            font-weight: 500;
            color:black;
            opacity: 1;
            width: 7rem;
        }


        // *** Sites (data)
        .task-site-data-column-subdata-1 {
            font-family: FF_Din_Font_Medium, Arial;
            font-weight: 500;
            color:black;
            opacity: 1;
            width: 100%;
        }

        .task-site-data-column-subdata-2 {
            position: relative;
            display: block;
            font-family: FF_Din_Font_Medium, Arial;
            font-weight: 800;
            opacity: 0.4;
            margin-left:10px !important;

        }
    }

    
    // *** Event Description
    .task-data-column-sub-2 {
          word-break: break-word;

         // *** Open Tasks (data)
        .task-list-data-column-subdata-1 {
            font-family: FF_Din_Font_Medium, Arial;
            font-weight: 500;
            color:black;
            opacity: 1;
            user-select: text;
            // width: 7rem;
        }
    }

    // icon online 10 / 10
    .task-site-data-column-sub-2 {

        .task-site-online-container-color {
            background-color: rgba(86, 202, 0, 0.16);
        }
        .task-site-offline-container-color {
            background-color: rgba(250, 107, 107, 0.16);
        }

        .task-site-online-container {

            border-radius: 20px;
            padding-block: 1px;
            padding-inline: 7px;

            .task-site-icon-online-color {
              background-color: rgba(86, 202, 0, 0.66);
            }
            .task-site-icon-offline-color {
              background-color: rgba(202, 0, 0, 0.66);
            }

            .task-site-icon-online {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            .task-site-online-label-1 {
              font-family: FF_Din_Font_Medium;
              // font-size: 0.875rem;
            }

            .task-site-online-label-2 {
              font-family: FF_Din_Font_Medium;
              color:grey;
              // font-size: 0.775rem;
            }
        }

    }
  }

  .task-site-title-column-sub-2 {
      text-align: right;
  }

  // Column di bagian Title "Open Tasks"
  .task-list-title-column-sub-1 {
      width: 7rem;
  }

  // Icon Open Tasks
  .task-list-title-column-sub-icon {
      width: 4rem;
  }
}

@media (max-width:576px){
  .task-container-row-2 {
    width: 100% !important; // full width align dengan total width 4 card di atas (full Row 2)
  }
}

// ... end

.ppe-anly-datepicker-parent {
  width: 100%;

  .react-datepicker__input-container {
    display: flex;
    height:100%;

    .react-datepicker-ignore-onclickoutside {
      width: 100%;
    }

    input[type="text"] {
      width: 100%;
    }
  }
}

.ppe-card-body-title {
  color:white;
  font-family: FF_Din_Font;
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 1.2rem;
  text-shadow:1px 1px 1px maroon;
  text-align: center;
}

// * banner title dan image di atas
.camol-title-container {
  margin-top:70px;
}

@media (max-width:576px) {
  .camol-title-container {
    margin-top:-10px;
  }
}

.camol-title {
  font-family: Montserrat, Titillium_Web_Bold;
  // font-weight: 700;
  color: darkcyan;
  // text-shadow: 1px 2px 5px grey;
}
.camol-subtitle {
  font-family: Montserrat, Titillium_Web_Bold;
  font-weight: 500;
  color:rgb(0, 174, 174);
}



.camol-icon-tag-parent {
    position: relative;
    
    // ** icon bulat pada tag
    .camol-icon-tag-radius {
      position:absolute;
      display: block;
      left:5px;
      top:50%;
      transform: translateY(-50%);
      // background-color: green;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .custom-tag.p-tag {
        padding-left:20px;
        // padding-block: 0px;
    }
}

.camol-custom-selectbutton-group {
    .p-button {
      border-radius: revert-layer;
      padding-inline:15px;
      padding-block: 10px;
    }
}

// *** Atur prime react multi select
.custom-multiselect-prime {
  max-width: 250px;

  .p-multiselect-label-container {
    // text-overflow: ellipsis;
    width: 120px; // lebar agar ada jarak dengan icon clear
  }
}
.p-multiselect-items {
  padding-left: 0;
}
.p-multiselect-filter-container {
  input {
    height: 39px;
  }
}
@media (max-width:767px){
  .custom-multiselect-prime {
    width: 100% !important;
    max-width: 100% !important;
  }
}
// ...

// *** Card Grid
.camol-card-grid-container {
  // paling atas
  opacity: 0;
  // transform: translateY(100%);
  border:none !important;
  box-shadow: 1px 1px 3px 1px lightsteelblue;
  animation: down-to-up .5s ease-in-out forwards;
  width: 24% !important;
  overflow:auto;

  @keyframes down-to-up {
      from{transform: translateY(100%); opacity: 0;}
      to{transform: translateY(0%); opacity: 1;}
  }

  .camol-card-icon-container {
    position: relative;
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  
  .camol-card-name {font-weight: 500; font-family: Arial; color: grey}

  .camol-card-ket1 {font-family: FF_Din_Font_Bold;}
}

// @media (max-width:1300px)  {
//   .camol-card-grid-container {
//       width: 23% !important;
//   }
// }
@media (max-width:767px)  {
  .camol-card-grid-container {
      width: 45% !important;
  }
}
@media (max-width:576px)  {
  .camol-card-grid-container {
      width: 100% !important;
  }
}

.camol-card-icon-color-0 {
  background-color: #fde3e3;
  // ** warna icon
  i {color:rgba(242,90,90,1);}
}
.camol-card-icon-color-1 {
  background-color: #74dbf71a;
  
  i {color:rgba(84,203,236,1);}
}
.camol-card-icon-color-2 {
  background-color: #e7e3fd;

  i {color:rgba(116,90,242,1);}
}
.camol-card-icon-color-3 {
  background-color: #e4f5d6;
  
  i {color:rgba(133,212,70,1);}
}
// ...

// Style Button PrimeReact
.p-button {
  border-radius: 6px;
  padding: 0.25rem 0.75rem;

  .p-button-label {
    font-weight: 700;
    font-family: Inter Var, FF_Din_Font;
  }

  // size button
  &.custom-prime-size-small {
    font-size: 0.875rem;
    padding:0.35625rem 0.79375rem;
  }

  &.custom-prime-size-normal {
    font-size: 1rem;
    padding:0.75rem 1.25rem;
  }
}


// .ppe-card-dash-main {

//   // index card color
//   &.ppe-card-idx-0 {
//       .card-body {background: linear-gradient(to right,#fe9365,#feb798);}
//       .card-footer {background: linear-gradient(to right,#fb8a5a,#feb798);}
//   }
//   &.ppe-card-idx-1 {
//       .card-body {background: linear-gradient(to right, #0ac282, #0df3a3);}
//       .card-footer {background: linear-gradient(to right, #24c18a, #0df3a3);}
//   }
// }

.ppe-anly {

  .ppe-anly-datepicker {
      margin-left:15px;
      margin-bottom:15px;

      .react-datepicker-popper {
        z-index: 3;
      }

      .ppe-anly-datepicker-title {
        font-family: FF_Din_Font_Medium; 
        color: rgb(91, 111, 167);
      }

      .ppe-anly-datepicker-container {
        position: relative;
        .ppe-anly-datepicker-icon {
            position:absolute;
            z-index:4;
            margin-left:5px;
            top: 50%;
            transform: translateY(-50%);
  
        }
      }

      input {
        border: 1px solid #aeaeae;
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        font-size: 15px;
        line-height: 19px;
        padding: 6px 10px 6px 35px;

        &:focus {
          // border: #aeaeae;
          box-shadow: inset 0 2px 2px #e9e9e9,0 0 10px 0 rgba(73,107,125,.3);
          outline: none;
        }
      }
  }

  .MuiTabs-root {
      width:75%;
  }

  &::-webkit-scrollbar{
    height:7px;
    // background-color: grey;
  }
  &::-webkit-scrollbar-track{
    background-color: rgba(228, 225, 225, 0.595);
    
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 25px;
    // background-color: rgb(255, 183, 129);
    background: linear-gradient(to right, rgb(154, 192, 225), rgb(153, 225, 188),rgb(154, 225, 153));
  }
  
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;



  &.isDragging {
    cursor: grabbing;
  }

  // Card Tools
  .ppe-card-dash-top {

      border:none;
      display: flex;
      flex-direction: row;
      gap:15px;  
      overflow-x: auto;
      scroll-snap-type: x proximity;
      padding-bottom: 10px;
      padding-left:5px;
      padding-right:5px;


      .ppe-card-dash-top-sub {

          display:flex;
          flex:1;

          .ppe-card-dash-main {

                overflow-x: hidden;

                // index card color
                &.ppe-card-idx-0 {
                    .card-body {background: linear-gradient(to right,#fe9365,#feb798);}
                    .card-footer {background: linear-gradient(to right,#fb8a5a,#feb798);}
                }
                &.ppe-card-idx-1 {
                    .card-body {background: linear-gradient(to right, #0ac282, #0df3a3);}
                    .card-footer {background: linear-gradient(to right, #24c18a, #0df3a3);}
                }
                &.ppe-card-idx-2 {
                    .card-body {background: linear-gradient(to right, #fe5d70, #fe909d);}
                    .card-footer {background: linear-gradient(to right, #ff5064, #fe909d);}
                }
                &.ppe-card-idx-3 {
                    .card-body {background: linear-gradient(to right, #01a9ac, #01dbdf);}
                    .card-footer {background: linear-gradient(to right, #00b1b4, #01dbdf);}
                }
                &.ppe-card-idx-4 {
                    .card-body {background: linear-gradient(to right, #79c10e, #93e813);}
                    .card-footer {background: linear-gradient(to right, #7ab422, #93e813);}
                }
                &.ppe-card-idx-5 {
                    .card-body {background: linear-gradient(to right, #9755ae, #cb86f3);}
                    .card-footer {background: linear-gradient(to right, #8f54a4, #cb86f3);}
                }
                &.ppe-card-idx-6 {
                    .card-body {background: linear-gradient(to right, #5557ae, #6d6ead);}
                    .card-footer {background: linear-gradient(to right, #5456a3, #6d6ead);}
                }
                &.ppe-card-idx-7 {
                    .card-body {background: linear-gradient(to right, #559bae, #79bdd0);}
                    .card-footer {background: linear-gradient(to right, #68a6b8, #79bdd0);}
                }
                &.ppe-card-idx-8 {
                    .card-body {background: linear-gradient(to right, #bb7b67, #ca907e);}
                    .card-footer {background: linear-gradient(to right, #c18674, #ca907e);}
                }
                
                &.card {
                  flex:1;
                  border:none;
                  background-color: transparent;
                  // scroll-snap-align: center;
                  width:200px;
                }
                .card-body {
                  border-radius:10px 10px 10px 10px;
                  padding-top:1.7rem;
                  padding-bottom:1.7rem;

                  .ppe-card-body-title {
                      color:white;
                      font-family: FF_Din_Font;
                      letter-spacing: 2px;
                      font-weight: 700;
                      font-size: 1.2rem;
                      text-shadow:1px 1px 1px maroon;
                      text-align: center;
                  }
                }

                .card-footer{
                  color:white;
                  border-top-width: 0;
                  
                  // border-top-color: #f9dccf;
                  // font-size: 14px;
                  // border-radius:0 0 10px 10px !important;
                  
      
                  // .ppe-card-footer-datetime,
                  // .ppe-card-footer-cam {
                  //     font-style: italic;
                  //     font-size: 12px;
                  //     font-weight: 400;
                  // }
                }
          }
          
      }

  }

  // button scroll pada tab
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  // loader ketika ganti camera
  .ppe-anly-ppe-loader-main {
    height:50vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgb(242, 234, 234)
    // background-color: red;
  }

  @media (max-width:767px) {
    .MuiTabs-root {
      width:100%;
    }
  }
}

.ppe-card-dash-info-image-gal {
  // border:1px solid red;
  cursor: pointer;
  position: relative;
  padding-block: 10px;
  padding-inline: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 2px rgb(224, 224, 224);

  margin-bottom:70px;

  .ppe-random-img-container {
    height: 250px;
  }

  .ppe-card-dash-info-image-title {

      position: relative;


      div{
        font-size: 1.5rem;
        font-family: FF_Din_Font_Medium;
        // text-shadow: 0px 0px 2px grey;
        // background-color: darkcyan;
        // font-weight: bolder;
      }

      .ppe-img-gallery-back {
          border-radius:30px; 
          background-color:darkcyan;
          
          &:hover {
            cursor:pointer;
            background-color:rgb(0, 83, 83);
          }
      }

      .ppe-nodata-gallery-text {
          position: absolute;
          left:120px;
          top: 50px;
          color:rgb(160, 0, 134);
          // transform: translateX(-50%);
      }
  }
}

.ppe-anly {
  .ppe-nodata-dashanly {
      position: absolute;
      left:120px;
      top: 50px;
      color:rgb(160, 0, 134);
      font-size: 1.5rem;
      font-family: FF_Din_Font_Medium;
  }

  .ppe-btn-refresh {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
  }

  // image no data dalam custom tab panel
  .ppe-anly-nodata-cust-tabpanel {
      margin-bottom: 90px;
  }
}

