@import '../../../../assets/fonts/font_custom.scss';

.pre-title-submenu {
  padding: 0px 24px;
  margin-top:8px;
  margin-bottom: 8px;
  p {
      color:rgb(126, 149, 175);
      margin:0;
      letter-spacing: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      font-family: 'Poppins', 'Roboto', sans-serif;
  }
}


.submenu-custom {
  font-family: 'Titillium_Web_Regular','Comic Sans ms';
}

// menu yang paling bawah
.submenu-custom-2  .custom-menu-item  .ps-menu-label 
{
      text-wrap: wrap;
      padding-left:10px;
}


.side-left-sub {
  border:'1px solid green';
  position:'relative';
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;

  .ps-sidebar-root {
      width: 100%;
      min-width: 100%;
      .ps-sidebar-container {
          height: auto;
          // background-color: transparent;  // warna header bagian logo iot
      }
  }
}

.sidebarClasses  {
  width: auto;
  min-width: auto;
}


.logo-header {
    
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
  // padding-left:24px;

  // khusus ada image logo
  .logo-title-image {
      display: flex;
      width:100%;
      
      img {
          margin-left:10px;
          height:auto;
          width:150px;
      }
  }
  
  .logo-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size:24px;
      font-weight: 700;
      border-radius: 8px;
      color:white;
      width: 35px;
      background: linear-gradient(45deg, rgb(21, 131, 205) 0%, rgb(90, 255, 173) 100%);
      text-shadow: 0.5px 1px 1px dodgerblue, 1px 1.5px 1px antiquewhite;
      // margin-left: 4px;
  }

  .logo-subtitle {
      margin:0;
      padding:0;
      color: rgb(0, 152, 229);
      font-size: 20px;
      font-weight: 700;
      margin-left: 10px;
  }
}

.side-bottom {
  // display: flex;
  // justify-content: center;
  position: relative;
  display: block;
  padding-top:20px;
  height:160px;
  // border:1px solid blue;
  // overflow: hidden;

  .logo-img-bottom {
      background:url('../../../../assets/images/black tablet computer with graph illustration.png') no-repeat center;
      opacity:1;
      background-size: contain;
      width: 100%;
      height:140px;
      display: block;
      animation: logo-anim 1.5s ease-in-out;
      // top:30px;
      // border:1px solid red;
      // position: relative;
  }

  @keyframes logo-anim {
      from {
          transform: perspective(70px) rotateX(170deg) scale(0.1);
      }
      to{
          transform: rotateX(0) scale(1);
          width: 100%;
      }
  }
}

.sidemenu-label-submenu {
  text-decoration: none;
  // color: black;
}

.sidemenu-ham-menu {
  display: none;
}

@media (max-width:767px){
    .sidemenu-ham-menu {
      display: block;
    }
}