.ppe-anly-datepicker-parent {
  width: 100%;

  .react-datepicker__input-container {
    display: flex;
    height:100%;

    .react-datepicker-ignore-onclickoutside {
      width: 100%;
    }

    input[type="text"] {
      width: 100%;
    }
  }

  // atur posisi flex pada bulan agar inline
  .react-datepicker__month-wrapper {
    display: flex;
  }
}

// Atur prime react multi select
.custom-multiselect-prime {
  max-width: 250px;
}
.p-multiselect-items {
  padding-left: 0;
}
.p-multiselect-filter-container {
  input {
    height: 39px;
  }
}
@media (max-width:767px){
  .custom-multiselect-prime {
    max-width: 100% !important;
  }
}
// ...

// Style Button PrimeReact
.p-button {
  border-radius: 6px;
  padding: 0.25rem 0.75rem;

  .p-button-label {
    font-weight: 700;
    font-family: Inter Var, FF_Din_Font;
  }

  // size button
  &.custom-prime-size-small {
    font-size: 0.875rem;
    padding:0.35625rem 0.79375rem;
  }

  &.custom-prime-size-normal {
    font-size: 1rem;
    padding:0.75rem 1.25rem;
  }
}


// .ppe-card-dash-main {

//   // index card color
//   &.ppe-card-idx-0 {
//       .card-body {background: linear-gradient(to right,#fe9365,#feb798);}
//       .card-footer {background: linear-gradient(to right,#fb8a5a,#feb798);}
//   }
//   &.ppe-card-idx-1 {
//       .card-body {background: linear-gradient(to right, #0ac282, #0df3a3);}
//       .card-footer {background: linear-gradient(to right, #24c18a, #0df3a3);}
//   }
// }

.ppe-anly {

  .ppe-anly-datepicker {
      margin-left:15px;
      margin-bottom:15px;

      .react-datepicker-popper {
        z-index: 3;
      }

      .ppe-anly-datepicker-title {
        font-family: FF_Din_Font_Medium; 
        color: rgb(91, 111, 167);
      }

      .ppe-anly-datepicker-container {
        position: relative;
        .ppe-anly-datepicker-icon {
            position:absolute;
            z-index:4;
            margin-left:5px;
            top: 50%;
            transform: translateY(-50%);
  
        }
      }

      input {
        border: 1px solid #aeaeae;
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        font-size: 15px;
        line-height: 19px;
        padding: 6px 10px 6px 35px;

        &:focus {
          // border: #aeaeae;
          box-shadow: inset 0 2px 2px #e9e9e9,0 0 10px 0 rgba(73,107,125,.3);
          outline: none;
        }
      }
  }

  .MuiTabs-root {
      width:75%;
  }

  &::-webkit-scrollbar{
    height:7px;
    // background-color: grey;
  }
  &::-webkit-scrollbar-track{
    background-color: rgba(228, 225, 225, 0.595);
    
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 25px;
    // background-color: rgb(255, 183, 129);
    background: linear-gradient(to right, rgb(154, 192, 225), rgb(153, 225, 188),rgb(154, 225, 153));
  }
  
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;



  &.isDragging {
    cursor: grabbing;
  }

  // Card Tools
  .ppe-card-dash-top {

      border:none;
      display: flex;
      flex-direction: row;
      gap:15px;  
      overflow-x: auto;
      scroll-snap-type: x proximity;
      padding-bottom: 10px;
      padding-left:5px;
      padding-right:5px;


      .ppe-card-dash-top-sub {

          display:flex;
          flex:1;

          .ppe-card-dash-main {

                overflow-x: hidden;

                // index card color
                &.ppe-card-idx-0 {
                    .card-body {background: linear-gradient(to right,#fe9365,#feb798);}
                    .card-footer {background: linear-gradient(to right,#fb8a5a,#feb798);}
                }
                &.ppe-card-idx-1 {
                    .card-body {background: linear-gradient(to right, #0ac282, #0df3a3);}
                    .card-footer {background: linear-gradient(to right, #24c18a, #0df3a3);}
                }
                &.ppe-card-idx-2 {
                    .card-body {background: linear-gradient(to right, #fe5d70, #fe909d);}
                    .card-footer {background: linear-gradient(to right, #ff5064, #fe909d);}
                }
                &.ppe-card-idx-3 {
                    .card-body {background: linear-gradient(to right, #01a9ac, #01dbdf);}
                    .card-footer {background: linear-gradient(to right, #00b1b4, #01dbdf);}
                }
                &.ppe-card-idx-4 {
                    .card-body {background: linear-gradient(to right, #79c10e, #93e813);}
                    .card-footer {background: linear-gradient(to right, #7ab422, #93e813);}
                }
                &.ppe-card-idx-5 {
                    .card-body {background: linear-gradient(to right, #9755ae, #cb86f3);}
                    .card-footer {background: linear-gradient(to right, #8f54a4, #cb86f3);}
                }
                &.ppe-card-idx-6 {
                    .card-body {background: linear-gradient(to right, #5557ae, #6d6ead);}
                    .card-footer {background: linear-gradient(to right, #5456a3, #6d6ead);}
                }
                &.ppe-card-idx-7 {
                    .card-body {background: linear-gradient(to right, #559bae, #79bdd0);}
                    .card-footer {background: linear-gradient(to right, #68a6b8, #79bdd0);}
                }
                &.ppe-card-idx-8 {
                    .card-body {background: linear-gradient(to right, #bb7b67, #ca907e);}
                    .card-footer {background: linear-gradient(to right, #c18674, #ca907e);}
                }
                
                &.card {
                  flex:1;
                  border:none;
                  background-color: transparent;
                  // scroll-snap-align: center;
                  width:200px;
                }
                .card-body {
                  border-radius:10px 10px 10px 10px;
                  padding-top:1.7rem;
                  padding-bottom:1.7rem;

                  .ppe-card-body-title {
                      color:white;
                      font-family: FF_Din_Font;
                      letter-spacing: 2px;
                      font-weight: 700;
                      font-size: 1.2rem;
                      text-shadow:1px 1px 1px maroon;
                      text-align: center;
                  }
                }

                .card-footer{
                  color:white;
                  border-top-width: 0;
                  
                  // border-top-color: #f9dccf;
                  // font-size: 14px;
                  // border-radius:0 0 10px 10px !important;
                  
      
                  // .ppe-card-footer-datetime,
                  // .ppe-card-footer-cam {
                  //     font-style: italic;
                  //     font-size: 12px;
                  //     font-weight: 400;
                  // }
                }
          }
          
      }

  }

  // button scroll pada tab
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  // loader ketika ganti camera
  .ppe-anly-ppe-loader-main {
    height:50vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgb(242, 234, 234)
    // background-color: red;
  }

  @media (max-width:767px) {
    .MuiTabs-root {
      width:100%;
    }
  }
}

.ppe-card-dash-info-image-gal {
  // border:1px solid red;
  cursor: pointer;
  position: relative;
  padding-block: 1px;
  padding-inline: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 2px rgb(224, 224, 224);

  margin-bottom:40px !important;

  .ppe-random-img-container {
    height: 250px;
  }

  .ppe-card-dash-info-image-title {

      position: relative;


      div{
        font-size: 1.5rem;
        font-family: FF_Din_Font_Medium;
        // text-shadow: 0px 0px 2px grey;
        // background-color: darkcyan;
        // font-weight: bolder;
      }

      .ppe-img-gallery-back {
          border-radius:30px; 
          background-color:darkcyan;
          
          &:hover {
            cursor:pointer;
            background-color:rgb(0, 83, 83);
          }
      }

      .ppe-nodata-gallery-text {
          position: absolute;
          left:120px;
          top: 50px;
          color:rgb(160, 0, 134);
          // transform: translateX(-50%);
      }
  }
}

.ppe-anly {
  .ppe-nodata-dashanly {
      position: absolute;
      left:120px;
      top: 50px;
      color:rgb(160, 0, 134);
      font-size: 1.5rem;
      font-family: FF_Din_Font_Medium;
  }

  .ppe-btn-refresh {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
  }

  // image no data dalam custom tab panel
  .ppe-anly-nodata-cust-tabpanel {
      margin-bottom: 90px;
  }
}