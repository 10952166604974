.hamburger-menu {
  cursor: pointer;
  position: relative;
  // border:3px solid darkcyan;
  width: 30px;
  height: 25px;
  padding:3px;
  border-radius: 5px;
  // box-shadow: 0.7px 1px 1px 1px mediumaquamarine;

  // &.active {
      // box-shadow: 0.7px 1px 3px .4px darkgreen inset;
  // }

  .submenu {
      position: relative;
      border:0px solid red;
      height:100%;
      overflow: hidden;

      div:nth-child(1){
          position: absolute;
          // border:2px solid darkcyan;
          width: 100%;
          height: 5px;
          background-color: rgb(81, 81, 81);
          border-radius: 20px 20px 0px 0px;
          transform-origin:100% 0%;
          transition: transform 0.3s;
      }
      div:nth-child(2){
          position: absolute;
          // border:2px solid darkcyan;
          width: 100%;
          top:50%;
          height: 5px;
          background-color:rgb(118, 118, 118);
          border-radius: 0 0 0 0;
          transform:translateY(-50%);
          transform-origin: 100% 50%;
          transition: transform 0.3s;
      }
      div:nth-child(3){
          position: absolute;
          // border:2px solid darkcyan;
          border-radius: 0 0 20px 20px;
          width: 100%;
          bottom:0;
          height: 5px;
          background-color: rgb(160, 160, 160);
          transform-origin: 100% 100%;
          transition: transform 0.3s;
      }

      &.active {
          div:nth-child(1){
              transform: scaleX(.8);
              // top:-2px;
              // right:0px;
              // transform: rotate(-45deg) scaleX(1.7);
          }
          div:nth-child(2){
              transform: translateY(-50%) scaleX(.5);
              // display: none;
          }
          div:nth-child(3){
              transform: scaleX(1);
              // bottom:-1px;
              // right:1px;
              // transform: rotate(45deg) scaleX(1.7);
          }
      }

  }

}